
<template>
  <div class="div1">
      <div class="div2">
        <a :href="i.link" v-for="(i,j) in disArr" :key="j" target="_blank" class="tagName" :ref="'tagName' + j" style="margin-right: 118px;">
          <div style="color: #fff;margin-right: 10px;">
            <img src="~@/assets/home/s_icon.png" style="width: 32px;margin-right: 20px;">{{i.title}}
          </div>
        </a>
      </div>
  </div>  
</template>

<script>
// 初始位置
import { toRefs } from "vue";
import { useWallet } from "../hooks/useWallet";
var {useSubgraph} = toRefs(useWallet);
export default {
  name: 'my-marquee-left',
  props: {
  },
  data () {
    return {
      disArr: []
    }
  },
  mounted () {
    this.getNotices();
    setTimeout(()=>{
      let width = 0;
      let els = this.disArr;
      //console.log(this.disArr,'dis');
      let _this = this;
      for(var i = 0 ; i < els.length;i++){
        let tag = _this.$refs['tagName' + i];
        width+= tag[0].offsetWidth+118;
        // console.log(tag,'tag');
      }
      // console.log(width,'width');
      document.styleSheets[0].insertRule(
          "@keyframes anima" +
          "{" +
          "0%{transform: translateX(100%);}" +
          "100%{transform: translateX(-"+ width +"px);" +
          "}"
      )
    },1000)
  },
  beforeDestroy () {
  
  },
  methods: {
    getNotices(){
        useWallet.useSubgraph()
        .getNotices()
        .then((data) => {
          //  console.log(data, "getNotices");
            this.disArr = data;
        })
        .catch((error) => {
            console.log(error);
        });
    }
  }
}
</script>

<style scoped>
.div1 {
    width: 100%;
    height: 63px;
    line-height: 63px;
    background: linear-gradient(90deg, #FE890C 0%, #D549F5 100%);
    overflow: hidden;
    box-sizing: border-box;
}
.div1:hover .div2{
  -webkit-animation-play-state: paused; /*动画暂停播放*/
}
.div2 {
    white-space: nowrap;
    display: flex;
    color: #fff;
    font-size: 20px;
    font-family: "Black";
    animation: 25s anima linear infinite normal;
    cursor: pointer;
}


/* @keyframes anima {
    0% {
        transform: translateX(100%);
    }
    100% {
        transform: translateX(-550px);
    }
  } */
</style>


 

