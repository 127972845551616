<template>
    <div id="new_predict">
        <img src="~@/assets/predict/bg.png" alt="" style="width: 100%;">
        <div id="predictContent">
            <a-carousel arrows ref="tabList">
                <div
                    slot="prevArrow"
                    slot-scope="props"
                    class="custom-slick-arrow"
                    style="left: 10px;zIndex: 1" v-show="tabIndex == 0 ? false : true"
                >
                    <a-icon type="left" @click="handlePrev" :style="{color: '#ffffff' }" />
                </div>
                <div slot="nextArrow" slot-scope="props" class="custom-slick-arrow" style="right: 10px" v-show="tabIndex == 2 ? false : true">
                    <a-icon type="right" @click="handleNext" :style="{color: '#ffffff' }" />
                </div>
                <!--预测中-->
                <div>
                    <div class="tab tab3">
                        <a-row><img src="~@/assets/predict/iconmonstr.png" alt="" style="width:76px;height:76px;margin: 0 auto;margin-top: 8px;"> </a-row>
                        <div style="font-size:48px;color:#FDCD1C;line-height:59px;text-align:center;">
                            Congratulations!
                            <br>
                            <!--100 winners-->
                        </div>
                        <div style="padding:0 204px;margin-top: 24px;">
                            <a-row style="margin-bottom:18px;">
                                <a-col :span="12">{{$t('predict.CLOSINGPRICE')}}</a-col>
                                <a-col :span="12" style="text-align: right;">$ {{closedObj.closedPrice}}</a-col>
                            </a-row>
                            <a-row style="margin-bottom:18px;">
                                <a-col :span="8">{{$t('predict.OPENINGPRICE')}}</a-col>
                                <a-col :span="16" style="text-align: right;">$ {{closedObj.lockedPrice}}</a-col>
                            </a-row>
                            <a-row>
                                <a-col :span="6">{{$t('predict.PRIZES')}}</a-col>
                                <a-col :span="18" style="text-align: right;">{{closedObj.rewards}} {{closedObj.token}}</a-col>
                            </a-row>
                        </div>
                        <div class="predicting">{{closedObj.status}}</div>
                        <div class="price">
                            <div style="color: #FDCD1C;font-size:18px;">{{closedObj.object}} Price</div>
                            <div style="color:#D2D2D2;font-size:12px;"># {{closedObj.round}}（ {{closedObj.startTime}} ~ {{closedObj.endTime}}  UTC ）</div>
                        </div>
                    </div>
                </div>
                <div>
                    <div class="tab tab2">
                        <a-row><img src="~@/assets/predict/iconmonstr.png" alt="" style="width:76px;height:76px;margin: 0 auto;margin-top: 8px;"> </a-row>
                        <div class="lastPrice">{{$t('predict.LASTPRICE')}}</div>
                        <div class="cPrice">${{proceedObj.lastPrice}}</div>
                        <div style="padding:0 187px;">
                            <a-row style="margin-bottom:12px">
                                <a-col :span="12">{{$t('predict.OPENINGPRICE')}}</a-col>
                                <a-col :span="12" style="text-align: right;">$ {{proceedObj.lockedPrice}}</a-col>
                            </a-row>
                            <a-row style="margin-bottom:12px">
                                <a-col :span="6">{{$t('predict.PRIZES')}}</a-col>
                                <a-col :span="18" style="text-align: right;">{{proceedObj.rewards}} {{proceedObj.token}}</a-col>
                            </a-row>
                            <a-row style="margin-bottom:12px">
                                <a-col :span="12">{{$t('predict.UPPOSITION')}}</a-col>
                                <a-col :span="12" style="text-align: right;">{{proceedObj.up}}</a-col>
                            </a-row>
                            <a-row style="margin-bottom:12px">
                                <a-col :span="12">{{$t('predict.DOWNPOSITION')}}</a-col>
                                <a-col :span="12" style="text-align: right;">{{proceedObj.down}}</a-col>
                            </a-row>
                            <a-row>
                                <a-col :span="12">{{$t('predict.FLATPOSITION')}}</a-col>
                                <a-col :span="12" style="text-align: right;">{{proceedObj.flat}}</a-col>
                            </a-row>
                        </div>
                        <div class="predicting">{{proceedObj.status}}</div>
                        <div class="price">
                            <div style="color: #FDCD1C;font-size:18px;">{{proceedObj.object}} Price</div>
                            <div style="color:#D2D2D2;font-size:12px;"># {{proceedObj.round}}（ {{proceedObj.startTime}} ~ {{proceedObj.endTime}} UTC ）</div>
                        </div>
                    </div>
                </div>
                <div>
                    <a-spin :spinning="spinning" :delay="delayTime">
                        <div class="tab tab1">
                            <a-row><img src="~@/assets/predict/iconmonstr.png" alt="" style="width:76px;height:76px;margin: 0 auto;margin-top: 8px;"> </a-row>
                            <a-row class="win">{{$t('predict.PREDICTTOWIN')}} {{predictObj.object}} {{$t('predict.PREDICTTOWIN_')}}</a-row>
                            <div style="padding:0 166px;font-size: 16px;margin-top: 17px;">
                                <a-row>
                                    <a-col :span="12">{{$t('predict.PREDICTORS')}}</a-col>
                                    <a-col :span="12" style="text-align:right;">{{predictObj.count}}</a-col>
                                </a-row>
                                <a-row style="margin-top:14px;">
                                    <a-col :span="12">{{$t('predict.STAKED')}}</a-col>
                                    <a-col :span="12" style="text-align:right;">{{predictObj.stakes}} {{$t('predict.GUSDT')}}</a-col>
                                </a-row>
                                <a-row style="margin-top:51px;">
                                    <a-col :span="8">
                                        <div class="outbox">
                                            <div class="box">{{day[0]}}</div>
                                            <div class="box" style="margin-left:6px">{{day[1]}}</div>
                                        </div>
                                        <div class="outbox">{{$t('predict.DAY')}}</div>
                                    </a-col>
                                    <a-col :span="8">
                                        <div class="outbox" style="margin:0 auto;">
                                            <div class="box">{{hour[0]}}</div>
                                            <div class="box" style="margin-left:6px">{{hour[1]}}</div>
                                        </div>
                                        <div class="outbox" style="margin:0 auto;">{{$t('predict.HOUR')}}</div>
                                    </a-col>
                                    <a-col :span="8">
                                        <div class="outbox" style="float:right;">
                                            <div class="box">{{minute[0]}}</div>
                                            <div class="box" style="margin-left:6px">{{minute[1]}}</div>
                                            <div class="outbox">{{$t('predict.MINUTE')}}</div>
                                        </div>
                                       
                                    </a-col>
                                </a-row>
                            </div>
                            <div class="predicting">{{predictObj.status}}</div>
                            <div class="price">
                                <div style="color: #FDCD1C;font-size:18px;">{{predictObj.object}} Price</div>
                                <div style="color:#D2D2D2;font-size:12px;">
                                    # {{predictObj.round}} （ {{predictObj.startTime}} ~ {{predictObj.endTime}}  UTC ）
                                </div>
                            </div>
                        </div>
                    </a-spin>
                </div>
            </a-carousel>
            <div class="inWeek"> {{$t('predict.BTCUSD')}} <img src="~@/assets/predict/question.png" alt="" style="width:20px;height:20px;vertical-align: sub;margin-left: 10px;"></div>
            <a-row>
                <a-col :span="8" v-for="(item,index) in btnList" :key="index">
                    <div 
                        :class="['upDownBtn',item.status == true ? cssName :'']" 
                        @click="select(index)" 
                        :style="{float:index == 2?'right':'',margin:index == 1?'0 auto':''}">
                        {{item.name}}
                    </div>
                </a-col>
            </a-row>
            <div class="stake">{{$t('predict.STAKEGUSDT')}} {{gusdtObj.tokenName}}</div>
            <div style="position:relative;">
                <a-input placeholder="" 
                v-model="gUSDT" 
                class="gusdtVal" 
                @change="(e) => {gUSDT = $util.getMoney(e.target.value)}" @blur="changeOut"
                ref="gUsdtInput" />
                <div class="all" @click="getAll">{{$t('predict.ALL')}}</div>
            </div>
            <div class="point" v-if="pointStatus == true">
                {{pointTxt}}
              </div>
            <a-row style="margin:19px 0 76px 0;font-size: 18px;color: #D0D0D0;">
                <span style="font-family:'Bold'">{{$t('predict.AVAILABLE')}}: </span>
                <span style="font-family:'Medium'">{{gusdtObj.available}} {{gusdtObj.tokenName}}</span>
                <span style="color:#00A856;font-family:'Regular';margin-left: 56px;cursor: pointer;" @click="goDeposit" v-if="user != ''">{{$t('predict.DEPOSIT')}}</span>
            </a-row>
            <!-- <img src="~@/assets/home/pending.png" alt="" style="width:32px;height:32px;"> -->
            <div class="preBtn" @click="preClick">{{$t('predict.PREDICT')}}</div>
        </div>
         <!--小狐狸头 正在加载中-->
         <a-modal v-model="isOpen" :title="confirmTitle" :footer="null" width="850px">
            <div style="text-align:center;margin-top:130px;">
                <img src="~@/assets/icon/fox.png" alt="" style="width:80px;height:74px;">
                <div class="txt1">
                    {{$t('walletConfirm.CONFIRMTEXT')}}
                </div>
            </div>
        </a-modal>
        <!--成功-->
        <a-modal v-model="isSuccess" title="" :footer="null" width="850px">
            <div style="text-align:center;margin-top:74px;">
                <img src="~@/assets/icon/top.png" alt="" style="width:80px;height:80px;">
                <div class="txt2">
                    {{$t('submit.SUBMIT')}}
                </div>
                <a-button class="close" @click="isSuccessClose">
                    {{$t('submit.CLOSE')}}
                </a-button>
            </div>
        </a-modal>
        <!--失败-->
        <a-modal v-model="isRejectOpen" title="CONFIRM" :footer="null" width="850px">
            <div style="text-align:center;margin-top:74px;">
                <img src="~@/assets/icon/rejected.png" alt="" style="width:80px;height:80px;">
                <div class="txt3">
                    Transaction Rejected
                </div>
                <div class="txt4">
                    Staking Rejected    
                </div>
                <a-button class="close" @click="isRejectClose" style="margin-top: 68px;">
                    CLOSE
                </a-button>
            </div>
        </a-modal>
        <!--预测失败提示-->
        <a-modal v-model="isFail" title="CONFIRM" :footer="null" width="850px">
            <div style="text-align:center;margin-top:74px;">
                <img src="~@/assets/icon/rejected.png" alt="" style="width:80px;height:80px;">
                <div class="txt3">
                    Transaction Rejected
                </div>
                <div class="txt4">
                    You can predict only one time in a round
                </div>
                <a-button class="close" @click="isFailClose">
                    CLOSE
                </a-button>
            </div>
        </a-modal>
         <!--预测确认弹框confirm-->
        <a-modal v-model="isConfirm" :title="calculateTitle" :footer="null" width="850px">
            <div style="padding:40px 0 0 0;text-align: center;">
              <div style="position:relative;">
                <a-input v-model="gUSDT" class="confirmInput" disabled style="padding:0 70px 0 0;
        text-align: right;" />
                <span class="stake1">{{$t('calculate.STAKE')}}</span>
                <span class="pusdt">{{$t('calculate.PUSDT')}}</span>
              </div>
              <div style="text-align:center;">
                <img src="~@/assets/icon/down.png" alt="" style="width:40px;height:40px;margin-top:28px;margin-bottom:24px;">
              </div>
              <div style="position:relative;margin-bottom: 72px;">
                <a-input class="confirmInput" disabled style="padding:0 70px 0 0;text-align:right;" />
                <div class="stake1">{{$t('calculate.TOPREDICT')}}</div>
                <div class="pusdt1">{{$t('calculate.THE')}} <span style="color:#455EE7;">{{predictObj.object}}</span> {{$t('calculate.PRICEWILLBE')}} <span style="color:#455EE7;">statusName</span> {{$t('calculate.INTHENEXT')}}.</div>
              </div>
              <a-button class="close" @click="rePositConfirmBtn">
                  {{$t('calculate.CONFIRM')}}
              </a-button>
            </div>
        </a-modal>
    </div>
</template>

<script>
var timer,startTime,endTime,diffTime; //定义一个定时器
let _this;
export default {
  data() {
    return {
        btnList:[
            {name:_this.$t('predict.UP'),status:true},
            {name:_this.$t('predict.DOWN'),status:false},
            {name:_this.$t('predict.FLAT'),status:false}
        ],
        gUSDT:'',
        isConfirm:false,
        btnStatus:0,
        statusName:'',
        cssName:'up',
        predictObj:{},
        proceedObj:{},
        closedObj:{},
        day:[0,0],
        hour:[0,0],
        minute:[0,0],
        gusdtObj:{},
        isOpen:false,//confirm 狐狸头
        isSuccess:false,//成功
        isRejectOpen:false,//失败
        tabIndex:0,
        pointStatus:false,
        pointTxt:'',
        spinning:false,
        delayTime:500,
        user:'',
        isFail:false,//预测失败页面
        confirmTitle:_this.$t('walletConfirm.CONFIRM'),
        calculateTitle:_this.$t('calculate.CONFIRM')
    };
  },
  beforeCreate() {
        // 2.  在beforeCreate()中将当前组件的VM实例赋给变量_this
        _this= this;
  },
  create(){
    
  },
  mounted(){
    console.log('user');
    console.log(this.$store);
    this.user = this.$store.state.user;
    console.log(this.$store);
    this.$refs.gUsdtInput.focus();
    this.tabIndex = 2;
    this.$refs.tabList.goTo(2);
    setTimeout(() => {
        this.getPositions();
        this.getAccount();
    },500);
  },
  methods:{
        select(e){
            this.btnList.map((item) => {
                item.status = false;
            })
            this.btnStatus = e;
            //{{btnStatus == 0?'UP':(btnStatus == 1?'DOWN':'FLAT')}}
            this.statusName = this.btnStatus == 0?_this.$t('calculate.UP'):(this.btnStatus == 1?_this.$t('calculate.DOWN'):_this.$t('calculate.FLAT'));
            this.btnList[e].status = true;
            if(e == 0){
                this.cssName = 'up';
            }else if(e == 1){
                this.cssName = 'down';
            }else{
                this.cssName = 'flat';
            }
        },
        getPositions(){
            this.dappJS.Stats().getPositions().then(res => {
            console.log('positions');
            console.log(res);
                for(var i = 0 ; i < 3;i++){
                    res[i].startTime = this.$moment(res[i].lockedTime*1000).format('MM/DD');
                    res[i].endTime = this.$moment(res[i].closedTime*1000).format('MM/DD');
                    if(res[i].status == 'Predicting'){
                        this.predictObj = res[i];
                        console.log('obj');
                        console.log(this.predictObj);
                        
                    }else if(res[i].status == 'Proceeding'){
                        this.proceedObj = res[i]
                    }else{
                        this.closedObj = res[i]
                    }
                }
                this.getDurationTime();
            })
        },
        countNum(num){
            if(num.length == 1){
                return '0'+num;
            }else{
                return num;
            }
        },
        getDurationTime(){
            let time = this.predictObj.closeTime;
            
            //获取当前日期时间 
            startTime = this.$moment().format('YYYY-MM-DD HH:mm:ss'),
            endTime =  this.$moment(time*1000).format('YYYY-MM-DD HH:mm:ss');
            // console.log('time');
            // console.log(endTime);
            //days hours milliseconds minutes months seconds years

            let duration = this.$moment.duration(this.$moment(startTime).diff(this.$moment(endTime)))._data;
            console.log('duration');
            console.log(duration);
            let d = duration.days.toString();
            d = this.countNum(d);
            this.day.push(d.charAt(0));
            this.day.push(d.charAt(1));
            let h = duration.hours.toString();
            h = this.countNum(h); //split(' ').join('')
            this.hour.push(h.charAt(0));
            this.hour.push(h.charAt(1));
            let m = duration.minutes.toString();
            m = this.countNum(m);
            this.minute.push(m.charAt(0));
            this.minute.push(m.charAt(1));

            //计算毫秒
         diffTime = this.$moment(endTime).diff(this.$moment(startTime));
        // console.log('计算后的time');
        // console.log(diffTime);
        if(diffTime <= 0){

        }else{
            diffTime =  this.$moment(endTime).diff(this.$moment(startTime), 'seconds');
        }
            
        timer = setInterval(this.countDown,1000); 
        },
        getAccount(){
            this.dappJS.Stats().getAssets(this.dappJS.getAccount()).then(res => {
                this.gusdtObj = res[0];
                this.gusdtObj.available = this.$util.reserve(this.gusdtObj.available);
            })
        },
        preClick(){
            //判断是否有输入值
            if(this.gUSDT == ''){
                this.pointStatus = true;
                this.pointTxt = 'Please enter the quantity';
                return false;
            }else{
                this.pointStatus = false;
                this.pointTxt = '';
            }
            //如果输入的值大于余额
            if(parseInt(this.gUSDT) > this.gusdtObj.available){
                this.pointStatus = true;
                this.pointTxt = 'Insufficient balance';
                return false;
            }else{
                this.pointStatus = false;
                this.pointTxt = '';
            }
            //弹框确认。
            this.isConfirm = true;
        },
        rePositConfirmBtn(){
            this.isConfirm = false;//隐藏confirm
            //显示狐狸头
            this.isOpen = true;
            //debugger
            this.dappJS.PositionPool().predict(this.gUSDT,this.btnStatus)
            .then(res => {
                this.$notification.open({
                    message:_this.$t('toast.PENDING'),
                    description:'',
                    icon: <a-icon type="loading" style="color: #108ee9" />,
                    duration: null,
                })
                console.log('成功')
                //点击确认
                this.isOpen = false;
                this.isSuccess = true;
                //成功的toast
                res.wait(1)
                .then(data => {
                    this.$notification.open({
                        message:_this.$t('toast.PREDICTIONSUFFLY'),
                        description:'',
                        icon: <a-icon type="check-circle" theme="filled" style="color:#94bf88" />,
                        duration: 3,
                    })
                    //成功 并刷新页面
                    location. reload();
                })
                .catch(data => {
                    this.$notification.open({
                        message: _this.$t('toast.FAILEDTOPREDICT'),
                        description:'',
                        icon: <a-icon type="close-circle" theme="filled" style="color:#e88c85" />,
                        duration: 3,
                    })
                    //显示失败
                    this.isSuccess = false;
                    this.isRejectOpen = true;
                        
                })
            })
            .catch(res => {
                console.log('失败')
                //失败的toast
                this.isOpen = false;
                let fail = res.message.indexOf('PositionPool: already predicted');
                if(fail >= 0){
                    this.isFail = true;
                }
            })
        },
        getAll(){
            this.gUSDT = this.gusdtObj.available;
        },
        isRejectClose(){
            this.isRejectOpen = false;
        },
        isSuccessClose(){
            this.isSuccess = false;
        },
        handlePrev() {
            // 通过上边指定的ref 来操作
            this.$refs.tabList.prev();
            
            this.tabIndex--;
        },
        handleNext() {
            this.$refs.tabList.next();
            this.tabIndex++;
        },
        isFailClose(){
            this.isFail = false;
        },
        placeInput(val){
            let regs = val.split('.');
            if(regs.length > 1){
                
                let reg1 = regs[0];
                let reg2 = regs[1];
                if(reg2.length > 8){
                    reg2 = reg2.substring(0,8);
                    this.gUSDT = reg1 + '.' + reg2;
                }
            }
        },
        /**
         * 定时器*/
        countDown(){
            if(diffTime >= 0){
                
                var d = parseInt(diffTime / (24 * 60 * 60))
                d = d < 10 ? "0" + d : d
                var h = parseInt(diffTime / (60 * 60) % 24);
                h = h < 10 ? "0" + h : h
                var m = parseInt(diffTime / 60 % 60);
                m = m < 10 ? "0" + m : m
                var s = parseInt(diffTime % 60);
                s = s < 10 ? "0" + s : s
              //  console.log( d + '天' + h + '时' + m + '分' + s + '秒');

                this.day = [];
                this.day.push(d.toString().charAt(0));
                this.day.push(d.toString().charAt(1));

        //    console.log(this.day);
                this.hour = [];
                this.hour.push(h.toString().charAt(0));
                this.hour.push(h.toString().charAt(1));
            //   console.log(this.hour);
                this.minute = [];
                this.minute.push(m.toString().charAt(0));
                this.minute.push(m.toString().charAt(1));
            // console.log(this.minute);
                --diffTime;
                //console.log(diffTime);
            }else{
                //重新获取最新数据 并清空缓存
                clearInterval(timer);
                this.spinning = true;
                let _this = this;
                diffTime = 0;
                setTimeout(function(){
                    _this.spinning = false;
                    _this.getPositions();
                    _this.getAccount();
                },30000)
                
            }
        },
        goDeposit(){
            //跳转充值页面
            this.$router.push('/account');
        },
        changeOut(){
            //gusdtObj.available
            //gUSDT
            if(parseInt(this.gUSDT) > this.gusdtObj.available){
                this.pointStatus = true;
                this.pointTxt = 'Insufficient balance';
            }else{
                this.pointStatus = false;
                this.pointTxt = '';
            }
        }
    }
};
</script>

<style scoped>
::v-deep .ant-carousel .slick-dots li button{
    width: 9px;
    height: 9px;
    border: 1px solid #B4B4B4;
    border-radius: 50%;
    background: transparent;
}
::v-deep .ant-carousel .slick-dots li.slick-active button{
    background: #B4B4B4;
    opacity: 1;
    width: 9px;
    height: 9px;
    border-radius: 50%;
}
::v-deep .ant-carousel .slick-dots-bottom{
    bottom: -20px;
}
::v-deep .ant-carousel .slick-dots li{
    margin:0 8px;
}
::v-deep .ant-notification-notice-with-icon .ant-notification-notice-message{
    margin-top: 4px;
    margin-bottom:0;
}
 .ant-carousel >>> .custom-slick-arrow {
  width: 25px;
  height: 25px;
  font-size: 25px;
  color: #fff;
  opacity: 0.3;
}
.ant-carousel >>> .custom-slick-arrow:before {
  display: none;
}
.ant-carousel >>> .custom-slick-arrow:hover {
  opacity: 0.5;
}

.ant-carousel >>> .slick-slide h3 {
  color: #fff;
}
.tab{
    width: 100%;
    height: 382px;
    border-radius: 12px;
    color: #ffffff;
    position: relative;
}
.tab1{
    background-color: #5168E7;
}
.tab2{
    background-color: #08950A;
}
.tab3{
    background-color: #FD634E;
}
.win{
    font-size: 32px;
    font-family:'Bold';
    color: #FDCD1C;
    line-height: 39px;
    text-align: center;
    margin-top: 15px;
}
#predict{
    padding:0 0 50px 0;
}
#predictContent{
    /* width: 680px; */
    width: 55%;
    overflow: hidden;
    margin:0 auto;
    margin-top:-137px;
}
.predicting{
    padding: 3px 15px;
    border: 1px solid #fff;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 600;
    color: #FFFFFF;
    position: absolute;
    right: 17px;
    top: 13px;
}
.price{
    position: absolute;
    left: 14px;
    top: 9px;
}
.box{
    height: 52px;
    background-color: #F2F2F2;
    padding: 7px 11px;
    font-size: 36px;
    color: #000000;
    font-family:'Bold';
    font-weight: bold;
    float: left;
    border-radius: 4px;
    line-height: 39px;
}
.inWeek{
    color:#D0D0D0;
    font-size:16px;
    font-family:'Bold';
    line-height: 26px;
    margin-bottom: 15px;
    margin-top: 45px;
}
.upDownBtn{
    width: 200px;
    height: 70px;
    border:1px solid #979797;
    text-align: center;
    line-height: 70px;
    color: #979797;
    font-size: 24px;
    font-weight: 500;
    border-radius: 8px;
    cursor: pointer;
}
.up{
    background-color: #00A856;
    color: #ffffff;
}
.down{
    background-color: #F95A4E;
    color: #ffffff;
}
.flat{
    background-color: #FDCD1C;
    color: #ffffff;
}
.outbox{
    width: 90px;
    overflow: hidden;
    text-align: center;
}
.lastPrice{
    text-align:center;
    font-size:16px;
    font-weight: bold;
    font-family: 'Bold';
    line-height: 19px;
    margin-top: 8px;
}
.cPrice{
    font-size: 48px;
    font-family: 'Bold';
    font-weight: bold;
    color: #FDCD1C;
    line-height: 59px;
    text-align:center;
    margin:4px 0 23px 0;
}
.stake{
    color:#D0D0D0;
    font-size:16px;
    font-family:'Bold';
    font-weight: bold;
    line-height: 26px;
    margin-top:26px;
    margin-bottom: 4px;
}
.gusdtVal{
    width:100%;
    height: 70px;
    background: #FFFFFF;
    border-radius: 5px;
    border: 1px solid #DDDFE1;
    font-size: 24px;
    caret-color: #000000;
}
.gusdtVal:focus{
    border: 1px solid #5168E7;
}
.all{
    font-size: 24px;
    font-family: 'Medium';
    font-weight: 500;
    color: #344BCA;
    line-height: 33px;
    position: absolute;
    right:20px;
    top:20px;
    cursor: pointer;
}
.preBtn{
    /* background: #FF7335; */
    box-shadow: 0px 0px 10px 0px rgba(25,51,181,0.3);
    border:1px solid #FF7335;
    color: #FF7335;
    border-radius: 35px;
    font-size: 22px;
    font-family: 'Medium';
    font-weight: 500;
    line-height: 30px;
    padding: 20px 0;
    text-align: center;
    cursor: pointer;
}
.preBtn:hover{
    background-color: #FF7335;
    color: #fff;
}
.gusdtVal::-webkit-input-placeholder {
  font-size: 18px;
  font-family:'Bold';
  color: #969DAB;
}
.txt1{
    margin-top:30px;
    margin-bottom: 180px;
}
.txt2{
    margin-top: 40px;
    margin-bottom: 80px;
}
.txt3{
    margin-top: 40px;
    margin-bottom: 10px;
}
.txt4{
    margin-bottom: 68px;
}
.stake1{
  font-size: 14px;
  font-family: 'Medium';
  font-weight: 500;
  color: #969DAB;
  position: absolute;
  left: 18px;
  top: 25px;
}
.pusdt{
  font-size: 14px;
  font-family: 'Medium';
  font-weight: 500;
  color: #969DAB;
  position: absolute;
  right: 20px;
  top:25px;
}
.confirmInput{
  width:100%;
  height: 66px;
  border-radius: 6px;
  font-size: 22px;
  font-family: 'Medium';
  font-weight: 500;
  color: #333333!important;
  border:0;
  background-color: #e7e7e7!important;
}
.pusdt1{
    font-size: 18px;
    color: #333333;
    line-height: 27px;
    font-family: 'Regular';
    position: absolute;
    right: 20px;
    top:20px;
}
#new_predict{
    width: 100%;
    margin-top: 83px;
}
</style>