<template>
  <a-config-provider :locale="locale">
    <div id="app" style="height: 100%;">
      <component :is="guesslot"></component>
      <!-- <component :is="home"></component> -->
    </div>
  </a-config-provider>
</template>
<script>
import guesslot from './Guesslot.vue';

import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN'
import moment from 'moment';
import 'moment/locale/zh-cn';
moment.locale('en-us');

export default {
  data(){
    return{
      guesslot:guesslot,
      locale: zhCN
    }
  },
  mounted(){
  }
}
</script>
<style>
body{
  background-color: #FFFFFF!important;
  font-family: 'Medium'!important;
 
}
/* 隐藏鼠标点击这些元素时出现的光标 */
div, span, p {
    caret-color: transparent;
}
.point{
  color: #FF0000;
  font-size: 14px;
  margin-top: 7px;
}
</style>

