import Vue from 'vue';
import Vuex from 'vuex';
Vue.use(Vuex);

const store = new Vuex.Store({
  state:{ // 放置初始状态 app启动的时候的全局的初始值
    user:sessionStorage.getItem('userInfo'),
    isLine:sessionStorage.getItem('isLine'),
  },
  mutations:{
    USER_INFO(state,v){
      sessionStorage.setItem('userInfo',v);
      state.user = v;
    },
    USER_LINE(state,v){
      sessionStorage.setItem('')
    }
  },
  actions:{
  } 
})

// 整合初始状态和变更函数，我们就得到了我们所需的 store
// 至此，这个 store 就可以连接到我们的应用中
export default store