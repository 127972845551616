
<template>
  <div class="wrap">
    <div class="list">
      <div class="my-list" v-for="(item,index) in sendVal" :key='index'>
        <div class="my-uname">
            <a-col :span="6"><img :src="require(`../assets/home/${item.url}.png`)" alt="" class="winner_icon"></a-col>
            <a-col :span="7" class="w_name">{{item.account}}</a-col>
            <a-col :span="11" class="w_gusdt" style="text-align: right;">{{item.roll}}</a-col>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'winner-left',
  props: {
   sendVal:{
       type:Array,
       default:[]
   }
 },
  data () {
    return {
    }
  },
  created(){
  },
  mounted () {
    //this.getWinner();
  },
  beforeDestroy () {
  
  },
  methods: {
  }
}
</script>

<style scoped>
* {
        margin: 0;
        padding: 0;
      }
      .wrap {
        width: 100%;
        height: 100px;
        overflow: hidden;
        position: relative;
        padding-left: 3480px;
      }
      .list {
        list-style: none;
        width: 6960px;
        height: 100px;
        position: absolute;
        top: 0px; /*right:0px;向右*/
        left: 0px; /*向左*/
        animation: mymove 34.8s 1s infinite linear;
        display: flex;
        align-items: center;
        .my-list{
          margin:0 12px;
          display: inline-block;
          font-size: 24px;
          line-height: 50px;
          padding: 20px 16px; 
          width: 324px;
          border-radius: 20px;
          border: 1px solid #7C8097;
          .my-uname{
              color: #FFFFFF;
              font-size: 14px;
              .winner_icon{
                  width: 56px;
              }
              .w_name{
                  font-weight: 500;
              }
              .w_gusdt{
                  color: #665DF5;
                  font-family: 'Roman';
              }
              .winner_icon{
                object-fit: contain;
                width: 56px;
                height: 56px;
              }
            }
          }
      }
      @keyframes mymove {
        from {
          left: 0px;
        }
        to {
          left: -100%;
        }
      }
      .list:hover {
        animation-play-state: paused;
        cursor: pointer;
      }

</style>