<template>
  <div class="wrap">
    <div class="list">
      <div class="my-list" v-for="(item,index) in sendVal" :key='index'>
        <img :src="item.image" alt="" style="width: 100%;">
        <div class="my-uname p_line">
          {{item.tital}}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'winner-left',
    props: {
     sendVal:{
         type:Array,
         default:[]
     }
   },
    data () {
      return {
      }
    },
    created(){
    },
    mounted () {
      //this.getWinner();
    },
    beforeDestroy () {
    
    },
    methods: {
    }
  }
  </script>
  
  <style scoped>
  * {
          margin: 0;
          padding: 0;
        }
        .wrap {
          width: 100%;
          height: 410px;
          overflow: hidden;
          position: relative;
          padding-left: 3680px;
        }
        .list {
          list-style: none;
          width: 7360px;
          height: 410px;
          position: absolute;
          top: 0px; /*right:0px;向右*/
          left: 0px; /*向左*/
          animation: mymove 36.8s 1s infinite linear;
          display: flex;
          align-items: center;
          .my-list{
            margin:0 12px;
            display: inline-block;
            font-size: 24px;
            line-height: 50px;
            padding: 24px; 
            width: 368px;
            border-radius: 20px;
            border: 2px solid #2C21AA;
            .my-uname{
              color: #FFFFFF;
              font-size: 24px;
              font-family: 'Black';
              margin-top: 24px;
              line-height: 40px;
              }
            }
        }
        @keyframes mymove {
          from {
            left: 0px;
          }
          to {
            left: -100%;
          }
        }
        .list:hover {
          animation-play-state: paused;
          cursor: pointer;
        }
  
  </style>