import Vue from "vue";
import VueRouter from "vue-router";
import predict from "../views/NEWPREDICT.vue";
import home from "../HOME.vue";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: home,
  },
  {
    path: "/predict",
    name: "predict",
    component:() =>  import("@/views/NEWPREDICT")
  },
  {
    path: "/predicts",
    name: "predicts",
    component:() =>  import("@/views/NEWSPREDICT")
  },
  {
    path: "/winners",
    name: "winners",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/WINNERS.vue"),
  },
  {
    path:'/history',
    name:'history',
    component: () => 
      import("@/views/HISTORY.vue"),
  },
  {
    path:'/account',
    name:'account',
    component: () => 
      import("@/views/ACCOUNT.vue"),
  },
  {
    path:'/predictlist',
    name:'predictlist',
    component: () => 
      import("@/views/PREDICTLIST.vue"),
  },
  {
    path:'home',
    name:'home',
    component: () => 
      import("../HOME.vue"),
  },{
    path:'/guesslot',
    name:'guesslot',
    component: () => 
      import("@/Guesslot.vue"),
  }
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});

export default router;
