const m = {
    tab:{
        PREDICT:'预测',
        WINNERS:'榜单',
        HISTORY:'历史',
        ACCOUNT:'账户'
     },
    footer:{
        COMMUNIT:'社区',
        TWITTER:'Twitter',
        DISCORD:'Discord',
        HELP:'帮助',
        GUIDEES:'操作指南',
        FAQ:'常见问题',
        DEVELOPERS:'开发者',
        GITHUB:'Github'
    },
    home:{
        GUESSFREELYWINGIB:'无损失 放心猜',
        GUESSOLTISTHEFIRST:'Guesslot是第一个实现了无损化预测市场的去中心化协议。',
        GUESSOLTISTHEFIRST_:'人们可以更自由地参与预测，而不必担心损失本金。',
        MAKEAGUESS:'猜一下',
        HOWTOPLAY:'新手指南',
        SIMPLEPRODUCT:'操作简单 轻松上手',
        DEPOSIT:'存款',
        SELECTAPRIZE:'选择一个奖池，存入资金并获得gToken（存款凭证）。Guesslot会将资金存入优质且安全的借贷协议（如AAVE），以获得稳定收益。',
        PREDICT:'预测',
        PREDICTBYSTAKING:'使用gToken参与预测，Guesslot使用高可靠性的主流预言机，以保证结果的公平公正。',
        WIN:'开奖',
        THEPERSONWHO:'预测正确者赢得奖金，奖金来自于借贷协议的利息。其他参与者可取回全部的本金，而不会有任何损失。'
    },
    predict:{
        PREDICTING:'预测中',
        PRICE:'（BTC/USD）价格',
        PREDICTTOWIN:'预测',
        PREDICTTOWIN_:'价格赢大奖！',
        PREDICTORS:'参与者',
        STAKED:'质押量',
        HOUR:'时',
        MINUTE:'分',
        DAY:'天',
        PROCEEDING:'待开奖',
        LASTPRICE:'最新价',
        OPENINGPRICE:'开盘价',
        PRIZES:'奖金',
        UPPOSITION:'猜涨人数',
        DOWNPOSITION:'猜跌人数',
        FLATPOSITION:'猜平人数',
        CLOSED:'已结束',
        CLOSINGPRICE:'收盘价',
        BTCUSD:'BTC/USD 在下周的价格走势',
        UP:'上涨',
        DOWN:'下跌',
        FLAT:'持平',
        STAKEGUSDT:'质押',
        GUSDT:'gUSDT',
        ALL:'全部',
        AVAILABLE:'可用',
        DEPOSIT:'充值',
        PREDICT:'预测'    
    },
    winners:{
        TIME:'时间',
        POOL:'奖池',
        OBJECT:'预测对象',
        ROUND:'期数',
        WINNER:'获奖者',
        PRIZES:'奖金'
    },
    history:{
        POOL:'奖池',
        OBJECT:'预测对象',
        ROUND:'期数',
        PREDICTEDPOSITION:'预测结果',
        STAKED:'质押量',
        STATUS:'状态',
        OPERATION:'操作',
        Won:'已获奖',
        Closed:'已结束',
        Proceeding:'待开奖',
        Claimed:'已领奖',
        Expired:'已过期',
        CLAIM:'领奖'
    },
    account:{
        ASSETS:'资产',
        TOKEN:'币种',
        AVAILABLE:'可用',
        STAKED:'质押',
        TOTAL:'总计',
        OPERATION:'操作',
        DEPOSIT:'存款',
        WITHDRAW:'取款'
    },
    frame:{
        APPROVE:'授权',
        APPROVEUSDT:'授权USDT',
        APPROVE:'授权'
    },
    walletConfirm:{
        CONFIRM:'确认',
        CONFIRMTEXT:'请在你的钱包中确认交易'
    },
    submit:{
        SUBMIT:'关闭',
        CLOSE:'CLOSE'
    },
    depositFrame:{ 
        DEPOSIT:'存款',
        AVAILABLE:'可用',
        ALL:'全部'
    },
    depositsubmit:{
        CONFIRM:'确认',
        DEPOSIT:'存入',
        TORECEIVE:'获得',
        CONFIRM:'确定'
    },
    withdrawFrame:{ 
        WITHDRAW:'取款',
        AVAILABLE:'可用',
        ALL:'全部'
    },
    withdrawsubmit:{
        CONFIRM:'确认',
        BURN:'销毁',
        TORECEIVE:'获得',
        CONFIRM:'确定'
    },
    calculate:{
        CONFIRM:'确认',
        STAKE:'质押',
        PUSDT:'pUSDT',
        TOPREDICT:'预测',
        INTHENEXT:'下周会',
        THE:'',
        PRICEWILLBE:'（BTC/USD）的价格',
        UP:'上涨',
        DOWN:'下跌',
        FLAT:'持平'
    },
    claim:{
        CLAIMPRIZES:'领奖',
        CLAIM:'领奖'
    },
    toast:{
        PENDING:'处理中',
        APPROVESUFFLY:'授权成功',
        FAILEDTOAPPROVE:'授权失败',
        DEPOSTISUFFLY:'存款成功',
        FAILEDDEPOSIT:'存款失败',
        WITHDRAWEDSUFFLY:'取款成功',
        FAILEDWITHDRAW:'取款失败',
        PREDICTIONSUFFLY:'预测提交成功',
        FAILEDTOPREDICT:'预测提交失败',
        CLAIMEDSUFFLY:'领奖成功',
        FAILEDTOCLAIM:'领奖失败'
    },
    error:{
        QUANITY:'请输入数量',
        BALANCE:'余额不足'
    }
}

export default m;