import moment from 'moment';
export default{
    //小数点后面只保留8位
    reserve(val){
        let str = val.toString();
        let ary = str.split('.');
        if(ary[0] != ""){
            let newStr = ary[1].substring(0,8);
            return ary[0] + '.' + newStr;
        }
    },
    getMoney (value) { // 价格的限制规则，只能输入小数点后两位
        value = value.replace(/[^\d.]/g, '')// 清除"数字"和"."以外的字符
        value = value.replace(/^\./g, '')// 验证第一个字符是数字而不是字符
        value = value.replace(/\.{2,}/g, '.')// 只保留第一个.清除多余的
        value = value.replace('.', '$#$').replace(/\./g, '').replace('$#$', '.')
        value = value.replace(/^(\-)*(\d+)\.(\d\d\d\d\d\d\d\d).*$/, '$1$2.$3')// 只能输入两个小数
        return value
    },
    getDatTime(e){
       // console.log(moment,'this');
        return moment(e*1000).format('YYYY-MM-DD HH:mm');
    }
}