const m = {
    tab:{
       PREDICT:'Predict',
       WINNERS:'Winners',
       HISTORY:'History',
       ACCOUNT:'Account'
    },
    footer:{
        COMMUNIT:'Community',
        TWITTER:'Twitter',
        DISCORD:'Discord',
        HELP:'Help',
        GUIDEES:'Guides',
        FAQ:'FAQ',
        DEVELOPERS:'Developers',
        GITHUB:'Github'
    },
    home:{
        GUESSFREELYWINGIB:'Freely Guess Without Loss',
        GUESSOLTISTHEFIRST:'Guesslot is the first decentralized protocol to realize the lossless prediction market.',
        GUESSOLTISTHEFIRST_:'People can participate in predicting more freely without worrying about losing principal.',
        MAKEAGUESS:'Predict Now',
        HOWTOPLAY:'How to Play',
        SIMPLEPRODUCT:'Simple product Easy operation',
        DEPOSIT:'DEPOSIT',
        SELECTAPRIZE:'Select a prize pool, deposit funds and obtain gTokens(deposit certificate). Guesslot will transfer the funds into superior and safe lending protocols like Aave to obtain stable income.',
        PREDICT:'PREDICT',
        PREDICTBYSTAKING:'Predict by staking gTokens, Guesslot uses high-reliability mainstream oracles to ensure fair and just results.',
        WIN:'WIN',
        THEPERSONWHO:'The persons who predict correctly win the prizes, which come from the interest of the lending protocols. Other participants can get their full principal back without any loss.'
    },
    predict:{
        PREDICTING:'Predicting',
        PRICE:'（BTC/USD）Price',
        PREDICTTOWIN:'Predict',
        PREDICTTOWIN_:'price to win!',
        PREDICTORS:'Predictors',
        STAKED:'Staked',
        HOUR:'HOUR',
        MINUTE:'MINUTE',
        DAY:'DAY',
        PROCEEDING:'Proceeding',
        LASTPRICE:'Last Price',
        OPENINGPRICE:'Opening Price',
        PRIZES:'Prizes',
        UPPOSITION:'Up Position',
        DOWNPOSITION:'Down Position',
        FLATPOSITION:'Flat Position',
        CLOSED:'Closed',
        CLOSINGPRICE:'Closing Price',
        BTCUSD:'BTC/USD PRICE IN NEXT WEEK',
        UP:'UP',
        DOWN:'DOWN',
        FLAT:'FLAT',
        STAKEGUSDT:'STAKE',
        GUSDT:'gUSDT',
        ALL:'ALL',
        AVAILABLE:'Available',
        DEPOSIT:'DEPOSIT',
        PREDICT:'PREDICT'    
    },
    winners:{
        TIME:'Time',
        POOL:'Pool',
        OBJECT:'Object',
        ROUND:'Round',
        WINNER:'Winners',
        PRIZES:'Prizes'
    },
    history:{
        POOL:'Pool',
        OBJECT:'Object',
        ROUND:'Round',
        PREDICTEDPOSITION:'Predicted Position',
        STAKED:'Staked',
        STATUS:'Status',
        OPERATION:'Operation',
        Won:'Won',
        Closed:'Closed',
        Proceeding:'Proceeding',
        Claimed:'Claimed',
        Expired:'Expired',
        CLAIM:'Claim'
    },
    account:{
        ASSETS:'Assets',
        TOKEN:'Token',
        AVAILABLE:'Available',
        STAKED:'STAKED',
        TOTAL:'Total',
        OPERATION:'Operation',
        DEPOSIT:'Deposit',
        WITHDRAW:'Withdraw'
    },
    //授权弹框
    frame:{
        APPROVE:'APPROVE',
        APPROVEUSDT:'APPROVE USDT',
        APPROVE:'APPROVE'
    },
    //钱包确认弹框（通用）
    walletConfirm:{ 
        CONFIRM:'Confirm',
        CONFIRMTEXT:'Confirm the transation in your wallet '
    },
    //交易已提交弹窗
    submit:{ 
        SUBMIT:'Transaction Submitted',
        FAIL:'Transation Rejected',
        CLOSE:'Close'
    },
    //存款弹框
    depositFrame:{  
        DEPOSIT:'Deposit',
        AVAILABLE:'Available',
        ALL:'ALL'
    },
    //存款确认弹窗
    depositsubmit:{ 
        CONFIRM:'Confirm',
        DEPOSIT:'DEPOSIT' ,
        TORECEIVE:'TO RECEIVE',
        CONFIRM:'Confirm'
    },
    //取款弹窗
    withdrawFrame:{
        WITHDRAW:'Withdraw',
        AVAILABLE:'Available',
        ALL:'ALL'
    },
    //取款确认弹窗
    withdrawsubmit:{ 
        CONFIRM:'Confirm',
        BURN:'BURN',
        TORECEIVE:'TO RECEIVE',
    },
    //预测确认弹窗
    calculate:{
        CONFIRM:'Confirm',
        STAKE:'STAKE',
        PUSDT:'pUSDT',
        TOPREDICT:'To Predict',
        INTHENEXT:'in the next week',
        THE:'the',
        PRICEWILLBE:'price will be',
        UP:'UP',
        DOWN:'DOWN',
        FLAT:'FLAT'
    },
    //领奖弹窗
    claim:{
        CLAIMPRIZES:'Claim Prizes',
        CLAIM:'Claim'
    },
    //Toast
    toast:{
        PENDING:'Pending',
        APPROVESUFFLY:'Approved successfully',
        FAILEDTOAPPROVE:'Failed to approve',
        DEPOSTISUFFLY:'Deposited successfully',
        FAILEDDEPOSIT:'Failed to deposit',
        WITHDRAWEDSUFFLY:'Withdrawed successfully',
        FAILEDWITHDRAW:'Failed to withdraw',
        PREDICTIONSUFFLY:'Prediction submitted successfully',
        FAILEDTOPREDICT:'Failed to submit prediction',
        CLAIMEDSUFFLY:'Claimed prizes successfully',
        FAILEDTOCLAIM:'Failed to claim prizes',
    },
    //错误提示
    error:{
        QUANITY:'Please enter the quantity',
        BALANCE:'Insufficient balance'
    }

}

export default m;