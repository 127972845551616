<template>
    <a-layout style="background-color: #FFFFFF!important;">
        <!-- <div id="gueNavApp" :class="urlName ==''?'navHomeBgColor':'navBgColor'"> -->
        <!-- <div id="gueNavApp" class="navBgColor">
            <a-row>
                <a-col :span="12">
                    <img src="~@/assets/logo.png" alt="" style="width:40px;height:40px;margin-right:11px;vertical-align: sub;">
                    <span class="logoTxt" style="font-size:16px;">GUESSLOT</span>
                </a-col>
                <a-col :span="12">
                    <a-popconfirm
                        title="Are you sure delete this task?"
                        ok-text="Yes"
                        cancel-text="No"
                        @confirm="confirm"
                        @cancel="cancel"
                    >
                        <a href="#">Delete</a>
                    </a-popconfirm>
                </a-col>
            </a-row>
        </div> -->
        <a-layout-header id="gueNav">
        <!-- <a-layout-header id="gueNav" :class="urlName ==''?'navHomeBgColor':'navBgColor'"> -->
            <a-row>
                <a-col :span="2" @click="goHome" style="cursor:pointer;">
                    <img src="~@/assets/home/Logo.png" alt="" style="width: 136px">
                </a-col>
                <a-col :span="9">
                    <a-row id="navList">
                        <a-col :span="4" style="margin-left: 59px;">
                            <router-link to="/predictlist">{{$t('tab.PREDICT')}}</router-link> 
                        </a-col>
                        <a-col :span="4">
                            <router-link to="/winners">{{$t('tab.WINNERS')}}</router-link>
                        </a-col>
                        <a-col :span="4" v-show="interLink == 1">
                            <router-link to="/history">{{$t('tab.HISTORY')}}</router-link>
                        </a-col>
                        <a-col :span="4" v-show="interLink == 1">
                            <router-link to="/account">{{$t('tab.ACCOUNT')}}</router-link>
                        </a-col>
                    </a-row>
                </a-col>
                <a-col :span="13">
                    <div style="display: flex;color: #FFFFFF;float: right;">
                        <div>
                            <a-tooltip>
                                <template slot="title">
                                    <div class="f_c_box1"  v-if="linkAry.length>0">
                                        <div class="fox_region" @click="linkChange(i)" v-for="(i,j) in linkAry" :key="j">
                                            <img :src="require(`./assets/home/${i.id}.jpg`)" alt="" class="foxIcon">  {{i.name}}
                                        </div> 
                                    </div>
                                </template>
                                <a-button class="connect" style="border: 0;width: auto;width: 200px;">
                                    <img :src="require(`./assets/home/${connectIcon}.jpg`)" alt=""> {{connetName}} 
                                    <a-icon type="down" :style="{ color: '#FFFFFF!important',marginLeft:'28px' }" />
                                </a-button>
                            </a-tooltip>
                        </div>
                        <div v-if="interLink == 1">
                            <div class="connect" style="margin-left: 24px;">
                                <span class="status"></span> {{account}}
                            </div>
                        </div>
                        <div v-else>
                            <a-tooltip>
                                <template slot="title">
                                    <div class="f_c_box">
                                        <div class="fox_region" @click="connetWallet(1)">
                                            <img src="~@/assets/nav/fox.png" alt="" class="foxIcon">  Metamask
                                        </div>
                                    </div>
                                </template>
                                <a-button class="connect_btn" style="margin-left: 24px;">Connect Wallet</a-button>
                            </a-tooltip>
                        </div>
                        <div style="text-align: right;">
                            <img src="~@/assets/home/help.png" alt="" style="width:22px;height:23px;cursor: pointer;margin: 0 45px;" @click="goGuess"> 
                            <a-tooltip>
                                <template slot="title">
                                    <div class="l_c_box">
                                        <div class="lang_region"  @click="changeLocale(null)">
                                            English
                                        </div>
                                    </div>
                                </template>
                                <img src="~@/assets/home/language.png" alt="" style="width:22px;height:22px;cursor:pointer;">
                            </a-tooltip>
                        </div>
                    </div>
                </a-col>
            </a-row>
        </a-layout-header>
        <a-layout-content id="commenContent">
            <router-view />
        </a-layout-content>
    </a-layout>
</template>

<script>
import { toRefs } from "vue";
import { useWallet } from "./hooks/useWallet";
import {getChains } from 'guesslot-js';

var { account, chainId, chainName,connect, disconnect, usePool, useSubgraph, useStats, useGToken } = toRefs(useWallet);
const { switchChain } = useWallet;

const EN = 'en-us'
const ZH = 'zh-cn'
export default {
  data() {
    return {
        langShow:false,
        account:'',
        name:'',
        predict:{
            asset:{
                name:'',
                time:''
            },
            settleTime:'',
            prize:'',
            stakeToken:{
                balance:'',
                symbol:''
            }
        },
        interLink:0,
        urlName:'',
        locale: null,
        linkAry:[],
        connetName:'Polygon',
        connectIcon:'connect'
        // connetName:'Polygon' || chainName==''?'Unsupported' : connetName,
        // connectIcon:'connect' || chainName==''?'failed' : connectIcon
    }
  },
  created(){
    
  },
  beforeMount(){
     
  },
  async mounted(){
    console.log(useWallet,'usewallet');
    this.linkAry = getChains();
    this.linkChange(this.linkAry[0]);
    console.log(this.linkAry,'link');
    if (!useWallet.account){
        this.connetWallet();
    }else{
        await useWallet.connect();
    }
  },
  watch:{
  },
  methods: {
    connetWallet(e){
        localStorage.setItem('userInfo','');
        useWallet.connect("MetaMask").then(() => {
            console.log("链接成功");
            console.log(account,'account');
            console.log(useWallet,'usewallet');
            this.chainName = account;
            this.interLink = 1;
            this.$store.commit('USER_INFO',useWallet);//请求回来后，把用户信息存储到VUEX里
            let firstName = account.value.slice(0,4);
            let lastName = account.value.substr((account.value.length)-4);
            this.account = firstName+'...'+lastName;
            if(e == 1){
                //刷新页面
                location.reload();
            }
        })
        .catch(data => {
            //链接失败后 清除缓存
            localStorage.setItem('userInfo','');
            console.log("链接失败", data);
            // this.$notification.open({
            //     message: 'Failed to connect wallet.',
            //     description:'',
            //     icon: <a-icon type="close-circle" theme="filled" style="color:#EC4848" />,
            //     duration: 3,
            // })
        });
        
    },
    goHome(){
        this.$router.push({path:'/'});
    },
    changeLocale(e){
        if(e == null){
            this.$i18n.locale = EN
            localStorage.setItem('lang','en-us')
            location.reload();
        }else{
            this.$i18n.locale = ZH
            localStorage.setItem('lang','zh-cn')
            location.reload();
        }
    },
    goGuess(){
        window.open('https://docs.guesslot.com/');
    },
    linkChange(e){
        this.connetName = e.name;
        this.connectIcon = e.id;
        switchChain(e.id).then(()=>{
        })
        .catch((error) => {
            //异常
            this.connetName = 'Unsupported';
            this.connectIcon = 'failed';
        });
    }
  }
};
</script>

<style lang="scss" scoped>
/* @media screen and (min-width:360px) and (max-width:1080px) {
    #gueNav{
        display: none;
    }
    #commenContent{
        margin-top: 25px;
    }
    #gueNavApp{
        display: inline;
    }
}
@media screen and (min-width:1080px) {
    #commenContent{
        margin-top:83px;
    }
    #gueNavApp{
        display: none;
    }
} */
#gueNav{
    width: 100%;
    height: 83px;
    background: #131419;
    line-height: 83px;
    z-index: 999;
    position: fixed;
    top: 0;
    left: 0;
}
/* #gueNavApp{
   padding: 18px;
   background-color: #344BCA;

} */

.navBgColor{
    /* background: #344BCA; */
}
.navHomeBgColor{
    background: linear-gradient(to left, #7B98FD 0%, #3763FD 100%);
}
.logoTxt{
  font-size: 24px;
  font-family: 'Bold';
  color: #FACC03;
}

#navList a{
    font-size: 16px;
    font-weight: 500;
    color: #FFFFFF;
}
::v-deep .router-link-exact-active,::v-deep .router-link-active{
    color: #C63596!important;
}
::v-deep .router-link-exact-active::after,::v-deep .router-link-active::after {
    content: '';
    width: 28px;
    height: 2px;
    display: block;
    margin-left: 13px;
    margin-top: -18px;
    border-bottom: 3px solid #C63596;
}

#mainnet{
    width: 250px;
    height: 36px;
    background: rgba(24, 117, 240, 0);
    border-radius: 5px;
    border: 1px solid #FFFFFF;
    color: #FFFFFF;
    font-family: 'Regular';
    font-size: 14px;
    padding: 7px 10px;
    float: right;
    margin-right: 28px;
    margin-top: 35px;
}
.status{
    width: 10px;
    height: 10px;
    background: #00A856;
    border-radius: 50%;
    margin-top: 5px;
    display: inline-block;
    margin-right: 16px;
}
.netbtn{
    width: 80px;
    height: 20px;
    border-radius: 10px;
    border: 1px solid #FFFFFF;
    line-height: 16px;
    text-align: center;
}
#language{
    width:22px!important;
    height: 22px;
    background: url('~@/assets/home/language.png') no-repeat;
    border:0;
    background-size: 100% 100%;
}
.langChoice{
    width: 100px;
    height: 86px;
    box-shadow: 0px 0px 20px 0px rgba(150, 157, 171, 0.1);
    border-radius: 12px;
    position: absolute;
    top: 76px;
    right: 23px;
    text-align: center;
}
.langH{
    height: 43px;
    line-height: 43px;
    cursor: pointer;
}
.ellipsis{
    display: inline-block;
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
    text-overflow:ellipsis;
    width: 120px;
    line-height: 20px;
}
.interlink{
    width: 250px;
    height: 36px;
    background: rgba(24, 117, 240, 0);
    border-radius: 5px;
    border: 1px solid #FFFFFF;
    font-size: 16px;
    font-family: 'Bold';
    font-weight: bold;
    color: #FFFFFF;
}
.ant-layout-header{
    padding: 0 62px 0 70px;
}
.connect{
    width: 148px;
    height: 42px;
    background: #1F2025;
    border-radius: 4px;
    text-align: center;
    line-height: 42px;
    color: #fff;
    font-weight: 500;
    margin-top: 20px;
    img{
        width: 32px;
        margin-right: 16px;
        vertical-align: middle;
    }
}
.connect_btn{
    width: 126px;
    height: 42px;
    text-align: center;
    line-height: 42px;
    color: #fff;
    background: linear-gradient(90deg, #C93393 0%, #605FFA 100%);
    border:0;
    border-radius: 4px;
    /* margin-left: 20px; */
}
#c_success_m{
    width: 175px;
    padding: 14px 0;
    line-height: 64px;
    color: #fff;
    background: #131419;
    border-radius: 4px;
}
#bulletBox{
    width: 112px;
    height: 90px;
    background: #131419;
    border-radius: 4px;
    color: #F1F2F2;
    font-weight: 500;
}
.f_c_box1{
    width: 200px;
    padding:10px 0;
    .foxIcon{
        width: 32px;
        margin-right: 13px;
    }
    .fox_region{
        width: 100%;
        cursor: pointer;
        padding: 0 32px;
        color: #F1F2F2;
    }
    .fox_region:hover{
        background: #1F2025
    }
}
.f_c_box{
    width: 130px;
    background: #131419;
    padding: 20px 0;
    text-align: center;
    border-radius: 4px;
    .foxIcon{
        width: 26px;
        height: 24px;
        margin-right: 16px;
    }
    .fox_region{
        width: 100%;
        cursor: pointer;
        padding: 6px 0;
        color: #F1F2F2;
    }
    .fox_region:hover{
        background: #1F2025
    }
}
.l_c_box{
    width: 112px;
    padding: 5px 0;
    background: #131419;
    border-radius: 4px;
    text-align: center;
    .lang_region{
        width: 100%;
        cursor: pointer;
        padding: 6px 0;
        color: #F1F2F2;
    }
    .lang_region:hover{
        background: #1F2025;
        color: #C83495;
    }
}

</style>
