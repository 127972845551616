import { render, staticRenderFns } from "./predictList.vue?vue&type=template&id=55c1432c&scoped=true&"
import script from "./predictList.vue?vue&type=script&lang=js&"
export * from "./predictList.vue?vue&type=script&lang=js&"
import style0 from "./predictList.vue?vue&type=style&index=0&id=55c1432c&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "55c1432c",
  null
  
)

export default component.exports